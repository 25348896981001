
import { defineComponent, ref, onMounted, reactive } from "vue";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

interface City {
  name: string;
  config: boolean;
  valuators: number;
  data: ScheduleDate;
}

interface ScheduleDate {
  start_time: string;
  end_time: string;
  days:
    | {
        id: string;
        day: string;
        label: string;
        active: boolean | number ;
      }[]
    | null;
  duration: number | null;
  quotas: number;
  schedule_type_id: number;
  city: string;
  id: number;
}

export default defineComponent({
  name: "ScheduleAddresView",
  setup() {
    const cities = ref<City[]>([]);
    const loading = ref<boolean>(true);
    const selectCity = ref<City | null>(null);
    const search = ref<string | null>("");
    const form = ref<ScheduleDate | null>(null);

    const validateEndTime = (rule: any, value: any, callback: any) => {
      if (value === "") {
        callback(new Error("Selecciona un horario de cierre"));
      } else {
        if (form.value) {
          if (parseInt(form.value.start_time) >= parseInt(form.value.end_time)) {
            callback(
              new Error("El horario de cierre debe ser mayor al horario de inicio")
            );
          }
        }
        callback();
      }
    };

    const checkDays = (rule: any, value: any, callback: any) => {
      if (form.value) {
        if (form.value.days?.some((day) => day.active)) {
          callback();
        } else {
          callback(new Error("Selecciona por lo menos un día"));
        }
      }
    };

    const formRef = ref<null | HTMLFormElement>(null);

    const rules = ref({
      days: [
        {
          required: true,
          validator: checkDays,
          trigger: "change",
        },
      ],
      start_time: [
        {
          required: true,
          message: "Selecciona un horario de inicio.",
          trigger: "change",
        },
      ],
      end_time: [
        {
          required: true,
          validator: validateEndTime,
          trigger: "change",
        },
      ],
      duration: [
        {
          required: true,
          message: "Selecciona una duración para las citas.",
          trigger: "change",
        },
      ],
    });

    const appointmenttime_options = [
      {
        value: "30",
        label: "30 minutos",
      },
      {
        value: "45",
        label: "45 minutos",
      },
      {
        value: "60",
        label: "1 hora",
      },
    ];

    const getCities = async () => {
      try {
        loading.value = false;
        const {
          data,
        } = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/schedules/cities-valuators`,
          { params: { search: search.value } }
        );
        cities.value = data.data;

        loading.value = true;
      } catch (error) {
        console.log(error);
      }
    };

    const setCity = (city: City) => {
      selectCity.value = city;

      if (city.data) {
        form.value = city.data;
        const duration: any = appointmenttime_options.filter((o) => parseInt(o.value) == city.data.duration );

        if (duration) {
          form.value.duration = duration[0].value;
        } else {
          form.value.duration = null;
        }

      } else {
        form.value = {
          start_time: "",
          end_time: "",
          days: [
            {
              id: "7",
              label: "D",
              day: "Domingo",
              active: 0,
            },
            {
              id: "1",
              label: "L",
              day: "Lunes",
              active: 0,
            },
            {
              id: "2",
              label: "M",
              day: "Martes",
              active: 0,
            },
            {
              id: "3",
              label: "MI",
              day: "Miércoles",
              active: 0,
            },
            {
              id: "4",
              label: "J",
              day: "Jueves",
              active: 0,
            },
            {
              id: "5",
              label: "V",
              day: "Viernes",
              active: 0,
            },
            {
              id: "6",
              label: "S",
              day: "Sábado",
              active: 0,
            },
          ],
          duration: null,
          quotas: 0,
          schedule_type_id: 3,
          city: city.name,
          id: 0,
        };
      }
    };

    const submit = async () => {
      try {
        if (!formRef.value) {
          return;
        }

        await formRef.value.validate(async (valid) => {
          if (valid) {
            loading.value = false;
            
            if (selectCity.value?.config) {
              try {
                await axios.put(`${process.env.VUE_APP_API_URL}/api/schedules/cities/${selectCity.value.data.id}`, form.value );
                
                Swal.fire({
                  text: "Se actualizó con éxito.",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Continuar",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
              } catch (error: any) {
                if (error.response.data.errors) {
                  let errors = error.response.data.errors;
                  let errorsToShow = "";

                  Object.keys(errors).forEach((key) => {
                    errorsToShow += errors[key][0] + "<br/>";
                    Swal.fire({
                      text: "Error",
                      html: errorsToShow,
                      icon: "warning",
                      buttonsStyling: false,
                      confirmButtonText: "Aceptar",
                      allowOutsideClick: false,
                      customClass: {
                        confirmButton: "btn btn-primary",
                      },
                    });
                  });
                }
              }
            } else {
              try {
                await axios.post(`${process.env.VUE_APP_API_URL}/api/schedules/cities`, form.value );

                Swal.fire({
                  text: "Se guardó con éxito.",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Continuar",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
              } catch (error: any) {
                if (error.response.data.errors) {
                  let errors = error.response.data.errors;
                  let errorsToShow = "";
                  Object.keys(errors).forEach((key) => {
                    errorsToShow += errors[key][0] + "<br/>";
                    Swal.fire({
                      text: "Error",
                      html: errorsToShow,
                      icon: "warning",
                      buttonsStyling: false,
                      confirmButtonText: "Aceptar",
                      allowOutsideClick: false,
                      customClass: {
                        confirmButton: "btn btn-primary",
                      },
                    });
                  });
                }
              }
            }
            await getCities();
            loading.value = true;
          } else {
            Swal.fire({
              text: "Se detectaron errores.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Continuar",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        });
      } catch (error: any) {
        console.log("Se encontraron", error);
      }
    };

    function createDebounce() {
      let timeout: any;
      return function (fnc, delayMs) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, delayMs || 500);
      };
    }

    onMounted(async () => {
      await getCities();
      setCurrentPageBreadcrumbs("Settings", ["Account"]);
    });

    return {
      loading,
      cities,
      form,
      selectCity,
      rules,
      appointmenttime_options,
      formRef,
      search,
      getCities,
      setCity,
      submit,
      debounce: createDebounce(),
    };
  },
});
